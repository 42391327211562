/* The navigation bar */
.navbar {
	overflow: hidden;
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 96px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0px 16px;
	z-index: 1;
}

.navbar-list a {
	padding: 4px 0px;
	margin: 0px 12px;
	text-decoration: none;
	color: rgba(9, 8, 9, 0.44);
	letter-spacing: 2px;
	font-size: 12px;
	font-weight: 400;
}

.navbar-list a.chosen,
.navbar-list a:hover {
	color: #090809;
	border-bottom: 1px solid #090809;
}

.navbar .icon {
  color: #090809;
  letter-spacing: 4px;
  font-size: 24px;
  font-weight: 300;
}

#navbar-burger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #090809; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
  
  /* The navigation menu links */
.sidenav a {
  padding: 12px 12px 12px 32px;
  text-decoration: none;
  letter-spacing: 4px;
  font-size: 25px;
  font-weight: 300;
  color: #818181;
  display: block;
  transition: 0.3s;
}
  
  /* When you mouse over the navigation links, change their color */
.sidenav a.chosen,
.sidenav a:hover {
	color: #f1f1f1;
}
  
  /* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Main content */
.content {
	margin-top: 96px;
}

@media screen and (max-width: 750px) {
  .navbar {
    height: 64px;
    justify-content: flex-end;
  }

  .content {
    margin-top: 64px;
  }

  .navbar-list a {
    display: none;
  }

  #navbar-logo {
    display: none;
  }

  #navbar-burger {
    display: flex;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


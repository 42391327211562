#albums-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#albums-container {
    width: 1250px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 64px;
}

.album-block {
    padding: 16px;
    cursor: pointer;
  }

  .image-wrapper {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .album-block img {
    width: 100%;
    vertical-align: top;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }

  .album-block :hover {
      opacity: 0.9;
  }

  .album-title {
      padding-top: 16px;
      text-align: center;
      letter-spacing: 2px;
      font-size: 12px;
      font-weight: 300;
      color: rgba(9, 8, 9, 0.8);;
  }

@media (max-width: 1500px) {
	#albums-container {
        width: 80%;
    }

    .album-block {
        padding: 8px;
    }
}
  
@media (max-width: 750px) {
	#albums-container {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }

    .album-block {
        padding: 8px;
    }
}
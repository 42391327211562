#about-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}

#about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 1000px;
}

.column {
    float: left;
}

#about-description {
    padding-left: 24px;
    width: 60%;
    flex: 0.6;
}

#about-description .title {
    letter-spacing: 4px;
    font-size: 20px;
    font-weight: 300;
    color: rgba(9, 8, 9, 1);
}

#about-description .text-body {
    letter-spacing: 2px;
	font-size: 16px;
    font-weight: 300;
    color: rgba(9, 8, 9, 0.8);
    text-align: justify;
}

#about-image {
    width: 40%;
    flex: 0.4;
    margin-bottom: 24px;
}

.column img {
    object-fit: contain;
    max-width: 100%;
}

@media (max-width: 1500px) {
    #about-container {
        width: 80%;
    }
}
  
@media (max-width: 750px) {
    #about-container {
        width: 100%;
    }

    #about-body {
        padding: 24px;
    }

    .column {
        width: 100%;
        flex: 100%;
    }

    #about-description {
        padding-left: 0;
        width: 100%;
        flex: 100%;
    }
    
    #about-image {
        width: 100%;
        flex: 100%;
    }
}
#contact-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px;
}

#contact-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 1000px;
}

.column {
    float: left;
}

#contact-description {
    padding-right: 24px;
    padding-bottom: 24px;
    width: 50%;
    flex: 0.5;
}

#contact-description .title {
    letter-spacing: 4px;
    font-size: 20px;
    font-weight: 300;
    color: rgba(9, 8, 9, 1);
}

#contact-description .text-body {
    letter-spacing: 2px;
	font-size: 16px;
    font-weight: 300;
    color: rgba(9, 8, 9, 0.8);
}

#contact-description a {
    letter-spacing: 2px;
	font-size: 16px;
    font-weight: 300;
    color: rgba(9, 8, 9, 0.48);
}

#contact-image {
    width: 50%;
    flex: 0.5;
}

.column img {
    object-fit: contain;
    max-width: 100%;
}

.divider {
    height: 16px;
}

@media (max-width: 1500px) {
    #contact-container {
        width: 80%;
    }
}
  
@media (max-width: 750px) {
    #contact-container {
        width: 100%;
    }

    #contact-body {
        padding-top: 0;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
    }

    .column {
        width: 100%;
        flex: 100%;
    }

    #contact-description {
        padding-left: 0;
        width: 100%;
        flex: 100%;
    }
    
    #contact-image {
        width: 100%;
        flex: 100%;
    }
}
.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	/*margin-left: -16px; /* gutter size offset */
	width: auto;
	padding-left: 4px;
	padding: 4px;
  }

  .my-masonry-grid_column {
	/*padding-left: 24px; */
	background-clip: padding-box;
  }
   
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
	
  }

  #description-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  #center-div {
    width: 500px;
    margin-bottom: 48px;
    margin-top: 24px;
  }

  #center-div .title {
    letter-spacing: 4px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: rgba(9, 8, 9, 1);
  }

  #center-div .text-body {
    letter-spacing: 2px;
	  font-size: 14px;
    font-weight: 300;
    text-align: justify;
    line-height: 1.5;
    color: rgba(9, 8, 9, 0.8);
  }

  img {
	  width: 100%;
	  padding-left: 4px;
	  padding-right: 4px;
	  padding-top: 4px;
  }

  @media (max-width: 575px) {
    #center-div {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
}